import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import useSiteNavigation from '../Navigation/SiteNavigation'
import useSiteMetadata from './SiteMetaData'
import { withPrefix } from 'gatsby'

import '../../styles/all.scss'

const Main = ({ children }) => {
  const AnimatedElements = [
    ".grid-image__container",
    "div.cover__image > picture > img",
    ".animate-on-scroll",
    ".hide",
    ".wipe-right",
    ".text-block__body",
    ".pull-quote__body"
  ];

  const setScrollStatus = () => {
    document.body.dataset.scrollStatus = (window.pageYOffset / (document.body.offsetHeight - window.innerHeight)*100);
  }

  const handleScrollAnimations = function(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting && !entry.target.classList.contains("is-visible") ) {
        entry.target.classList.add("is-visible");
      }
    });
  };

  useEffect(() => {
    const observerOpt = {
      root: null,
      rootMargin: "-100px",
      threshold: .0025
    }
    const targets = Array.from(document.querySelectorAll(AnimatedElements.toString()));
    const observer = new IntersectionObserver(handleScrollAnimations, observerOpt);
    targets.forEach(function(target) {
      observer.observe(target);
    });

    window.addEventListener('scroll', setScrollStatus, false);
  });

  const navigation = useSiteNavigation();
  const { title, description } = useSiteMetadata();
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-16x16.png`}
          sizes="16x16"
        />
        <link
          rel="mask-icon"
          href={`${withPrefix("/")}img/favicon-16x16.png`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content={`${withPrefix("/")}img/og-image.png`} />
      </Helmet>
      <Header nav={navigation} />
      {children}
      <Footer/>
    </>
  )
}

export default Main
