import React from 'react';
import './Section.scss';

export default class Section extends React.Component {

    constructor(props) {
      super(props);
      this.sectionElement = React.createRef();
      this.customClass = this.props.customClass || '';
    }

    componentDidMount() {
      if(this.customClass !== '' && typeof this.customClass === "string") {
        this.sectionElement.current.classList.add(this.customClass);
      } else if (
        this.customClass !== '' && typeof this.customClass === "object") {
        this.customClass.forEach((name) => {
          if(name) {
            this.sectionElement.current.classList.add(name);
          }
        })
      }
    }

    render() {
      return (
        <section id={this.props.id} ref={this.sectionElement} className="section">
          {this.props.children}
        </section>
      )
    }
}
