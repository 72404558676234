import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

const PreviewCompatibleImage = ({ imageInfo, customClassName }) => {
  const { alt='', childImageSharp, image, extension, publicURL } = imageInfo

  if (!childImageSharp && extension === 'svg') {
    return <img className={customClassName} src={publicURL} alt={alt} />
  }

  if (!!image && !!image.childImageSharp) {
    return <Img className={customClassName}  fluid={image.childImageSharp.fluid} alt={alt} />
  }
    
  if (!!childImageSharp) {
    return <Img className={customClassName} fluid={childImageSharp.fluid} alt={alt} />
  }

  if (!!image && typeof image === 'string')
    return <img className={customClassName} src={image} alt={alt} />

  return
}

PreviewCompatibleImage.propTypes = {
  imageInfo: PropTypes.shape({
    alt: PropTypes.string,
    childImageSharp: PropTypes.object,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    style: PropTypes.object,
  }).isRequired,
  customClass: PropTypes.string
}

export default PreviewCompatibleImage
