import React from 'react';
import './InnerContainer.scss';

export default class InnerContainer extends React.Component {

    constructor(props) {
        super(props);
        this.sectionElement = React.createRef();
        this.customClass = this.props.customClass || '';
    }

    componentDidMount() {
      if(this.customClass !== '' && typeof this.customClass === "string") {
        this.sectionElement.current.classList.add(this.customClass);
      } else if (
        this.customClass !== '' && typeof this.customClass === "object") {
        this.customClass.forEach((name) => {
          if(name) {
            this.sectionElement.current.classList.add(name);
          }
        })
      }
    }

    render() {
        return (
            <section ref={this.sectionElement} className="inner-container">
                {this.props.children}
            </section>
        )
    }
}
