import React from 'react';

function getCustomClasses(classes) {
  if(classes !== '' && typeof classes === "string") {
    return classes;
  } else if (
    classes !== '' && typeof classes === "object") {
    
    let classNames = ''
    classes.forEach((name) => {
      if(name) {
        classNames += ' ' + name;
      }
    })
    return classNames;
  }
  return '';
}

const Star = (props) => {

    return (
      <svg className={`animate-on-scroll star ${getCustomClasses(props.customClass)} hide` } viewBox="0 0 63.21 95.84">
          <path d="M29.6,10.2Q28.13,31.94,22.25,40c-3.92,5.32-11,9.22-21.25,11.76l.18,1q13.05-1.63,21.08,8.82,8.13,10.6,9.83,31.73h1Q34.95,69.39,40,61.5T59.25,49.6c.69-.2,1.36-.38,2-.55l-2.06-.42q-12.44-2.7-19.34-8.75c-4.6-4-7.65-14-9.28-29.7Q30.14,5.65,30,1.72,29.92,5.68,29.6,10.2Z"/>
      </svg>
    )
}

export default Star

