import { navigate } from "gatsby";

//Checking for the window object
export const isBrowser = () => typeof window !== "undefined";

// get the user object from the localstorage
export const getUser = () =>
  isBrowser() && window.localStorage.getItem("vmaUser")
    ? JSON.parse(window.localStorage.getItem("vmaUser"))
    : {};

// set the user object to local storage
const setUser = (vmaUser) =>
  window.localStorage.setItem("vmaUser", JSON.stringify(vmaUser));

export const handleLogin = (authResponse) => {
  if (authResponse.isValid) {
    return setUser(authResponse.userData);
  }

  return false;
};

// check the user is logged in or not
export const isLoggedIn = () => {
  const vmaUser = getUser();
  return !!vmaUser.username;
};

// logout the user
export const logout = () => {
  window.localStorage.removeItem('vmaUser');
  navigate("/");
};
