import React from 'react';
import Section from '../Layout/Section/Section'
import InnerContainer from '../Layout/InnerContainer/InnerContainer'
import MoonMan from '../Dividers/MoonMan';

import './Footer.scss';

const Footer = class extends React.Component {
  render() {
    return (
      <Section customClass="footer-container">
        <InnerContainer customClass="footer-content">
          <MoonMan/>
        </InnerContainer>
      </Section>
    )
  }
}

export default Footer
