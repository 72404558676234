import { graphql, useStaticQuery } from 'gatsby'

const useSiteMetadata = () => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query SITE_METADATA_QUERY {
        markdownRemark(frontmatter: {contentId: {eq: "site-metadata"}}) {
          frontmatter {
            siteMetadata {
              description
              title
              socialMediaUrls {
                facebook
                linkedin
              }
            }
          }
        }
      }
    `
  )
  return markdownRemark.frontmatter.siteMetadata
}

export default useSiteMetadata
