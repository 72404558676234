import React from 'react';
import { withPrefix } from "gatsby";
import Navigation from '../Navigation/Navigation'

import './Header.scss';

const PeekABooNavMenuClasses = {
  PEEK_MENU_INIT: 'header-container--peek',
  PEEK_MENU_SHOW: 'header-container--peek-show',
  PEEK_MENU_HIDE: 'header-container--peek-hide'
};

export default class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isPristine: true
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleShowHideMenu);
  }

  // this is for peek a boo
  /**
     * This method will perform checks against the <main> element's bounding top rect.
     * Since the <main> element is always placed below the header, the Sticky Menu will initialize
     * when the <main> element's top rect has scrolled vertically off of the top viewport which is 0.
     *
     * There are four Global Sticky Menu states:
     * Sticky Nav Menu Not Initialized (Not floating) {boolean} isBackInPlace
     * Sticky Nav Menu Initialized (Y Axis Position updated)
     * Sticky Nav Menu Initialized and Visible on screen (Animated Down)
     * Sticky Nav Menu Initialized and Hidden on screen (Animated Up)
     */
  handleShowHideMenu = () => {
    const headerElement = this.headerElement ? this.headerElement : window.document.body;
    const currentScrollPosition = window.pageYOffset;
    const headerElementRectBottom = headerElement.offsetTop + headerElement.offsetHeight;
    const headerNavElementHeight = headerElement.offsetHeight;

    if (this.lastScrollPosition === currentScrollPosition) {
      return;
    }

    const { classList } = headerElement;
    const { PEEK_MENU_SHOW, PEEK_MENU_HIDE, PEEK_MENU_INIT } = PeekABooNavMenuClasses;

    const isScrollingBackToTop = (this.lastScrollPosition > currentScrollPosition);
    const couldBeVisible = (headerElementRectBottom < currentScrollPosition);

    let isBackInPlace;

    if (headerNavElementHeight === 0) isBackInPlace = (headerElement.offsetTop >= currentScrollPosition);
    else { isBackInPlace = (headerElementRectBottom - headerNavElementHeight >= currentScrollPosition) }

    if (isBackInPlace && classList.contains(PEEK_MENU_INIT)) {
      this.headerElement.classList.remove(PEEK_MENU_INIT, PEEK_MENU_SHOW, PEEK_MENU_HIDE);
    } else if (couldBeVisible) {
      if (!classList.contains(PEEK_MENU_INIT)) {
        classList.add(PEEK_MENU_INIT);
      } else if (isScrollingBackToTop && classList.contains(PEEK_MENU_INIT)) {
        classList.remove(PEEK_MENU_HIDE);
        classList.add(PEEK_MENU_SHOW);
      } else if (!isScrollingBackToTop && classList.contains(PEEK_MENU_INIT)) {
        classList.add(PEEK_MENU_HIDE);
        classList.remove(PEEK_MENU_SHOW);
      }
    }

    this.lastScrollPosition = currentScrollPosition;
  }

  render() {
    return (
      <div ref={(e) => { this.headerElement = e }} className="header-container">
        <header className="header">
          <div className="brand-logo">
            <a href={withPrefix("/")} className="brand-logo__link" title="MTV VMA">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 149.81 35.93">
                <path id="vma" d="M32.27,1.41,25.64,8,19,1.41H1V9.93L25.64,34.52,50.27,9.93V34.52H69.06v-9.3L74.86,31l5.79-5.75v9.3h3c0-.55,0-1.86,0-2.56,0-3.94.35-4.84.35-10.52,0-1.7-.38-2.11-.93-2.11s-1.66.84-2.8,1.65c-1.83,1.3-2.81,2.34-4,2.34a1.8,1.8,0,0,1-1.54-.88,1.89,1.89,0,0,1-.32-1,1.44,1.44,0,0,1,.81-1.29,23.8,23.8,0,0,0,3.12-1.33c2-1.19,3.8-2.24,4.18-2.48.67-.35.51-.9.51-1.4,0-1.1,1-1.48,1.83-1.48a3.17,3.17,0,0,1,1.44.35c.3-.16,1.08-.61,2.56-1.52,1-.59,1.5-1.75,2.65-1.75a1.94,1.94,0,0,1,1.31.41c.58.47.68.73.68,1.14,0,.9-1.4,1.66-2.14,2.1-2.27,1.38-3,1.72-3.81,2.25a23.9,23.9,0,0,1,0,2.93c0,1.71.38,4.88.38,6.66,0,2.11-.09,4.72-.09,5.37a27.3,27.3,0,0,0,.22,3.07h29.46l6.64-6.63,6.64,6.63h18V26L124.17,1.41,99.54,26V21.16a38.57,38.57,0,0,0-2.88,7.54c-.55,1.89-.78,2.7-1.94,2.7s-1.4-.23-1.89-1.51S90.7,24.34,90.57,24a17.65,17.65,0,0,1-1.39-5.1,2.27,2.27,0,0,1,2.45-2.44c1.62,0,1.91,1.53,2.13,3,.11.75.18,2.81.3,3.9,0,.5,0,1,.57,1s.74-1.44,1.14-2.46a52.34,52.34,0,0,1,3.77-8V1.41H81.48L74.86,8,68.23,1.41Z"/>
              </svg>
            </a>
          </div>
          <Navigation nav={this.props.nav}/>
        </header>
        <svg id="header-wave-left" viewBox="0 0 1533 405" xmlns="http://www.w3.org/2000/svg">
          <path className="wave-1" d="M1416.61626,0 C1363.40422,4.57698723 1319.28615,23.0111497 1297.74118,50.74164 C1276.19621,78.4721304 1279.22672,103.34958 1238.63096,143.750707 C1229.47499,152.862772 1182.51872,178.320532 1095,184.374739 C970.640245,192.977467 605.640245,192.977467 1.13686838e-12,184.374739 L1.13686838e-12,267.42746 C660.225574,264.535624 1025.22557,260.72647 1095,256 C1199.66164,248.910295 1274.20785,218.358916 1297.74118,160.962937 C1323.64978,97.7738742 1291.66812,53.7954163 1353.14939,26.4998338 C1388.48378,10.8125708 1430.8825,4.92986504 1456.97978,0 L1416.61626,0 Z"/>
          <path className="wave-2" d="M1460.01806,1.42108547e-13 C1446.31591,2.25799147 1432.64161,4.48336315 1419.43535,6.99986497 C1374.24431,15.6111846 1334.53392,27.6315442 1317.94299,56.033545 C1296.50366,92.7355148 1316.15572,179.195446 1242.04846,217.244752 C1192.64362,242.610956 1136.85533,255.892258 1074.68358,257.088658 L1.8189894e-12,267.42746 L1.8189894e-12,342.688836 C590.819756,335.714582 951.504089,330.960697 1082.053,328.427183 C1277.87636,324.626911 1313.33204,266.453174 1335.35772,222.056269 C1366.17708,159.934059 1344.81584,122.883455 1356.49877,69.6413578 C1362.87224,40.5958655 1479.86748,5.65955828 1501,1.42108547e-13 L1460.01806,1.42108547e-13 Z"/>
          <path className="wave-3" d="M1501,-8.52651283e-14 C1482.424,3.67072515 1378.17131,24.1114576 1359.2739,62.197432 C1340.37649,100.283406 1367.72723,167.169652 1332.18478,222.572957 C1301.34117,270.651749 1249.55083,324.314632 1087,328.076537 C978.632782,330.584473 616.299449,335.455239 6.82121026e-13,342.688836 L6.82121026e-13,403.959539 C613.214929,397.052663 975.548262,393.599225 1087,393.599225 C1254.17761,393.599225 1346.17567,334.471321 1369.94988,257.816199 C1390.24726,192.371383 1381.86796,150.100427 1381.86796,86.8453874 C1381.86796,57.327177 1412.26996,26.8920224 1532.45166,-8.52651283e-14 L1501,-8.52651283e-14 Z"/>
        </svg>
        <svg id="header-wave-right" viewBox="0 0 962 393"  xmlns="http://www.w3.org/2000/svg">
          <path className="wave-1" d="M129.271,163.0437 C165.053,202.9427 248.813,310.5417 462.041,353.1587 C604.193,381.5697 770.608,394.4267 961.284,391.7297 L961.284,269.7677 C539.378,258.5687 325.096,251.3947 318.438,248.2447 C299.417,239.2447 279.163,232.9017 260.485,223.2087 C246.817,216.1157 185.146,177.6117 147.125,144.0057 C135.213,133.4757 92.005,85.4747 17.501,0.0007 L-2.27373675e-13,0.0007 C34.397,50.9537 77.488,105.3017 129.271,163.0437"/>
          <path className="wave-2" d="M149.7681,148.032 C257.2131,244.513 354.6251,267.884 435.9171,282.232 C512.4891,295.747 687.6111,291.593 961.2841,269.767 L961.2841,148.032 C718.7161,204.723 554.8931,232.716 469.8131,232.01 C318.6891,230.754 221.1681,159.552 166.6021,128.085 C151.9951,119.662 108.8921,76.967 37.2921,-1.13686838e-13 L17.5011,-1.13686838e-13 C72.5161,68.908 116.6041,118.252 149.7681,148.032"/>
          <path className="wave-3" d="M149.5601,115.4046 C256.5781,210.9466 350.2581,226.4486 442.3401,234.4456 C517.6811,240.9876 690.6631,212.1836 961.2841,148.0316 L961.2841,35.7076 C755.5421,99.5606 585.7571,141.3516 451.9291,161.0826 C404.4641,167.3536 305.4361,185.3506 165.3271,94.6046 C130.5401,72.0736 97.2651,40.5386 65.5021,0.0006 L37.2921,0.0006 C74.5101,43.3436 111.9331,81.8126 149.5601,115.4046"/>
        </svg>
      </div>
    )
  }
}
